// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\work\\JSICode\\jsi-blog\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("C:\\work\\JSICode\\jsi-blog\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("C:\\work\\JSICode\\jsi-blog\\src\\templates\\tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-contributors-js": () => import("C:\\work\\JSICode\\jsi-blog\\src\\templates\\contributors.js" /* webpackChunkName: "component---src-templates-contributors-js" */),
  "component---src-pages-404-js": () => import("C:\\work\\JSICode\\jsi-blog\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\work\\JSICode\\jsi-blog\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("C:\\work\\JSICode\\jsi-blog\\src\\pages\\tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

